import OidcCallbackError from "@/views/Oidc/OidcCallbackError.vue";
const oidcAuthRoutes = [
    {
        path: "/_oidc-callback",
        name: "OidcCallback",
        component: () => import(
        /* webpackChunkName: "bundle.oidc" */ "@/views/Oidc/OidcCallback.vue"),
    },
    {
        path: "/protected",
        name: "Protected",
        component: () => import(/* webpackChunkName: "protected" */ "@/views/Oidc/Protected.vue"),
    },
    {
        path: "/_oidc-callback-error",
        name: "OidcCallbackError",
        component: OidcCallbackError,
        meta: {
            isPublic: true,
        },
    },
];
const generalRoutes = [
    {
        path: "/",
        name: "Homepage",
        component: () => import(
        /* webpackChunkName: "bundle.test" */ "@/views/Admin/Home/AboutSkanlog.vue"),
        // meta: {
        //   isPublic: true
        // }
    },
    {
        path: "/view1",
        name: "View1",
        component: () => import(/* webpackChunkName: "bundle.initial" */ "@/views/Demo/View1.vue"),
    },
    {
        path: "/home/AboutSkanlog",
        name: "AboutSkanlog",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/Home/AboutSkanlog.vue"),
    },
    {
        path: "/Career",
        name: "Career",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/Career.vue"),
    },
    {
        path: "/contact/Location",
        name: "Location",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/ContactSkanlog/Location.vue"),
    },
    {
        path: "/contact/ContactInformation",
        name: "ContactInformation",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/ContactSkanlog/ContactInformation.vue"),
    },
    {
        path: "/Elev",
        name: "Elev",
        component: () => import(/* webpackChunkName: "bundle.initial" */ "@/views/Admin/Elev.vue"),
    },
    {
        path: "/Gallery",
        name: "Gallery",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/Gallery.vue"),
    },
    {
        path: "/NewsAndEvents",
        name: "NewsAndEvents",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/NewsAndEvents.vue"),
    },
    {
        path: "/MediaSliderManager",
        name: "MediaSliderManager",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/MediaSliderManager.vue"),
    },
    {
        path: "/AdminUserManagement",
        name: "AdminUserManagement",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/AdminUserManagement.vue"),
    },
    {
        path: "/Inquiries",
        name: "Inquiries",
        component: () => import(
        /* webpackChunkName: "bundle.initial" */ "@/views/Admin/Inquiries.vue"),
    },
    // Catch All 404 page
    { path: "/:pathMatch(.*)*", redirect: "/" },
];
const routes = [...oidcAuthRoutes, ...generalRoutes];
export default routes;
