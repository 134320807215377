import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@element-plus/icons";
import "element-plus/theme-chalk/index.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDCvmdIaIVRf1KXXdhXvzX6JKHW6fG8Pjk",
        libraries: "places",
    },
})
    .mount("#app");
