<template>
  <!-- <div>
      <el-icon v-if="isCollapse" @click="isCollapse = false">
        <More />
      </el-icon>
      <el-icon v-else @click="isCollapse = true">
        <ArrowLeftBold />
      </el-icon>
    </div> -->
  <!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
      <el-radio-button :label="false">expand</el-radio-button>
      <el-radio-button :label="true">collapse</el-radio-button>
    </el-radio-group> -->

  <el-menu
    class="sideMenu"
    :collapse="isCollapse"
    active-text-color="#409EFF"
    :default-active="activeLink"
    text-color="#909399"
    background-color="#FFFFFF"
    :router="true"
  >
    <!-- <el-image
			style="height: 20px"
			src="@/assets/Images/logo.png"
			:fit="fitType"
			v-if="!isCollapse"
		/> -->
    <img
      alt="Skanlog logo"
      id="skanlogLogo"
      src="@/assets/Images/SkanlogLogoHD.png"
    />
    <el-sub-menu index="1">
      <template #title>
        <el-icon><House /></el-icon>
        <span>Home</span>
      </template>
      <router-link to="/MediaSliderManager">
        <el-menu-item index="/MediaSliderManager">
          <el-icon><Menu /></el-icon>
          <span>Media Slider Manager</span>
        </el-menu-item>
      </router-link>

      <router-link to="/home/AboutSkanlog">
        <el-menu-item index="/home/AboutSkanlog">
          <el-icon><Menu /></el-icon>
          <span>About Skanlog</span>
        </el-menu-item>
      </router-link>
    </el-sub-menu>

    <el-sub-menu index="2">
      <template #title>
        <el-icon><Phone /></el-icon>
        <span>Contact SkanLog</span>
      </template>
      <router-link to="/contact/contactinformation">
        <el-menu-item index="/contact/contactinformation">
          <el-icon><Iphone /></el-icon>
          <span>Contact Information</span>
        </el-menu-item>
      </router-link>
      <router-link to="/contact/Location">
        <el-menu-item index="/contact/Location">
          <el-icon><MapLocation /></el-icon>
          <span>Location</span>
        </el-menu-item>
      </router-link>
    </el-sub-menu>

    <router-link to="/Elev">
      <el-menu-item index="/Elev">
        <el-icon><Menu /></el-icon>
        <span>ELEV</span>
      </el-menu-item>
    </router-link>
    <router-link to="/Career">
      <el-menu-item index="/Career">
        <el-icon><Menu /></el-icon>
        <span>Career</span>
      </el-menu-item>
    </router-link>
    <router-link to="/Gallery">
      <el-menu-item index="/Gallery">
        <el-icon><PictureFilled /></el-icon>
        <span>Gallery</span>
      </el-menu-item>
    </router-link>
    <router-link to="/NewsAndEvents">
      <el-menu-item index="/NewsAndEvents">
        <el-icon><DocumentCopy /></el-icon>
        <span>News and Events</span>
      </el-menu-item>
    </router-link>
    <router-link to="/AdminUserManagement">
      <el-menu-item index="/AdminUserManagement">
        <el-icon><DocumentCopy /></el-icon>
        <span>Admin User Management</span>
      </el-menu-item>
    </router-link>
    <router-link to="/Inquiries">
      <el-menu-item index="/Inquiries">
        <el-icon><DocumentCopy /></el-icon>
        <span>Manage Inquiries</span>
      </el-menu-item>
    </router-link>
  </el-menu>
</template>

<script>
import { defineComponent, ref } from "vue";
import {
  Menu,
  House,
  Phone,
  MapLocation,
  Iphone,
  PictureFilled,
  DocumentCopy,
} from "@element-plus/icons";

export default defineComponent({
  name: "SideMenu",
  components: {
    Menu,
    House,
    Phone,
    MapLocation,
    Iphone,
    PictureFilled,
    DocumentCopy,
  },
  mounted() {
    this.activeLink = this.$route.path;
    this.baseUrl = window.location.origin;
  },
  watch: {
    $route(newVal) {
      this.activeLink = newVal.path;
    },
  },
  data() {
    return {
      activeLink: "",
      baseUrl: "",
      // logoUrl: 'https://i.ibb.co/4Y8FLqB/Skanlog-Logo-HD.png',
      logoUrl: "@/assets/Images/logo.png",
      fitType: "scale-down",
    };
  },
  setup() {
    const isCollapse = ref(false);

    return { isCollapse };
  },
});
</script>
<style lang="scss" scoped>
.sideMenu {
  height: 98vh;
  background-color: #ffffff;
  border-width: 0 0 0 0;
  border-style: solid;
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
}
#skanlogLogo {
  max-height: 20px;
  margin-top: 1em;
}

.sideBarHeader {
  align-items: center;
  justify-content: space-around;
  min-width: 4em;
}

a {
  color: inherit;
  text-decoration: none !important;
}
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 200px;
// }
</style>
